import { ReportTypes } from '@/support/ReportTypes';
import { Rpc } from '@/models/Rpc';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { setFormattedDatePickerValue, isValidDate, dateErrorMessage } from '@/support/String';
import { User } from '@/models/User';
import { DateTime } from 'luxon';
import { StatisticsColumn } from '@/components/week-statistics-view/WeekStatisticsView';
import { ReportStatusMap, getStatusObjects, ReportStatus} from '@/support/ReportStatus';
import { Department } from '@/models/Department';
import { forEach, isArray, omitBy } from 'lodash';
import { ReportType } from '@/models/ReportType';
import { AppointmentType, AppointmentTypeLabels } from '@/models/Event';
import { WorkflowEnum, WorkflowLabels } from '@/components/filters/work-flow-filter/WorkFlowFilter';

@Component<ProductionStatisticsExperts>({})
export default class ProductionStatisticsExperts extends Vue {
  public $pageTitle = 'Productie Statistieken';

  protected isLoading = true;

  protected users: User[] | null = null;

  protected departments: Department[] = [];

  protected selectedDepartments: string[] = [];

  // status
  protected statusses: ReportStatusMap[] = getStatusObjects([ReportStatus.CREATED, ReportStatus.PLANNING, ReportStatus.WERKVOORBEREIDING_REJECTED, ReportStatus.OPNAME_WERKVOORBEREIDING_DONE]);

  protected selectedStatusses: string[] = [];

  // Report Type
  protected reportTypes: ReportType[] = [];

  protected selectedReportTypes: ReportType[] = [];

  protected selectableReportTypes: ReportTypes[] = [
    ReportTypes.AANNEMERS_VARIANT,
    ReportTypes.OLD_FORMAT_AANNEMERS_VARIANT,
    ReportTypes.WONING_COOPERATIE_1,
    ReportTypes.WONING_COOPERATIE_1_NEW,
    ReportTypes.WONING_COOPERATIE_2,
    ReportTypes.WONING_COOPERATIE_2_NEW,
  ];

  // breakdown
  protected appointmentTypes: { [key: string]: string }[] = [
    {
      name: 'OOA',
      key: 'opname_op_afstand',
    },
    {
      name: 'Fysieke afspraak',
      key: 'physical',
    },
    {
      name: 'Bel afspraak',
      key: 'call',
    },
  ];

  protected selectedAppointmentTypes = [];


  protected expertAppointmentTypes: { [key: string]: string }[] = [
    {
      name: AppointmentTypeLabels[AppointmentType.DEFAULT],
      key: AppointmentType.DEFAULT,
    },
    {
      name: AppointmentTypeLabels[AppointmentType.VERVOLGOPNAME],
      key: AppointmentType.VERVOLGOPNAME,
    },
    {
      name: AppointmentTypeLabels[AppointmentType.NADER_ONDERZOEK],
      key: AppointmentType.NADER_ONDERZOEK,
    },
    {
      name: AppointmentTypeLabels[AppointmentType.AANVULLEND_ONDERZOEK],
      key: AppointmentType.AANVULLEND_ONDERZOEK,
    },
    {
      name: AppointmentTypeLabels[AppointmentType.HOORZITTING],
      key: AppointmentType.HOORZITTING,
    },
    {
      name: AppointmentTypeLabels[AppointmentType.OPNAME_OP_AFSTAND],
      key: AppointmentType.OPNAME_OP_AFSTAND,
    },
    {
      name: AppointmentTypeLabels[AppointmentType.PHYSICAL],
      key: AppointmentType.PHYSICAL,
    },
    {
      name: AppointmentTypeLabels[AppointmentType.CALL],
      key: AppointmentType.CALL,
    },
  ];

  protected selectedExpertAppointmentTypes = [];

  protected workflows: { [key: string]: string }[] = [
    {
      name: WorkflowLabels[WorkflowEnum.REGULIER],
      key: WorkflowEnum.REGULIER,
    },
    {
      name: WorkflowLabels[WorkflowEnum.NADER_ADVIES],
      key: WorkflowEnum.NADER_ADVIES,
    },
  ];

  protected selectedWorkflows = [];

  protected planningTypes: { [key: string]: string }[] = [
    {
      name: 'Rapporten',
      key: 'reports',
    },
    {
      name: 'Afspraken',
      key: 'events',
    },
  ];

  protected selectedPlanningType = 'reports';

  protected actors: { [key: string]: string }[] = [
    {
      name: 'Opnemer',
      key: 'opnemer',
    },
    {
      name: 'Deskundige',
      key: 'deskundige',
    },
  ];

  protected selectedActor = 'opnemer';

  // columns
  protected columns: StatisticsColumn[] = [
    {
      key: 'name',
      name: 'Naam',
      class: 'xs4 md2 lg4',
    },
    {
      key: 'week',
      name: 'Maandag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Dinsdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Woensdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Donderdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Vrijdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Zaterdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Zondag',
      class: 'xs1 text-right',
    },
    {
      key: 'row_total',
      name: 'Totaal',
      class: 'xs1 text-right',
    },
  ];

  // dates
  protected isEditingDate = false;

  protected date = '';

  public mounted() {
    this.date = DateTime.local().toFormat('yyyy-LL-dd');
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    this.fetchUsers();
    this.getDepartments();

    if (this.$store.state.isServiceOrganization) {
      this.selectedPlanningType = 'events';
    }
  }

  protected getDepartments() {
    new Department()
      .all()
      .then((departments: Department[]) => {
        this.departments = departments;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getPlanningProductionStatisticsRpcPayload(): PlanningProductionStatisticsMediatorsRpc | PlanningProductionStatisticsExpertsRpc {
    const payload: PlanningProductionStatisticsMediatorsRpc | PlanningProductionStatisticsExpertsRpc = {
      signature: this.$store.state.isServiceOrganization
        ? 'planning:production-statistics-mediators'
        : 'planning:production-statistics-experts',
      body: {
        date: this.date,
        departments: this.selectedDepartments,
        ...this.$store.state.isServiceOrganization
          ? {
            appointment_types: this.selectedAppointmentTypes,
            planning_type: this.selectedPlanningType,
            report_types: this.selectedReportTypes,
          }
          : {
            actor: this.selectedActor,
            planning_type: this.selectedPlanningType,
            statusses: this.selectedStatusses,
            appointment_types: this.selectedExpertAppointmentTypes,
            workflows: this.selectedWorkflows,
          },
      },
    };

    payload.body = omitBy(payload.body, (value) => ! value || (isArray(value) && ! value.length)); // From payload.body remove keys where empty string or empty array

    return payload;
  }

  protected fetchUsers(): void {
    this.isLoading = true;

    new Rpc()
      .rpcPost(this.getPlanningProductionStatisticsRpcPayload(), false)
      .then((response: AxiosResponse) => {
        this.users = response.data;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  // FROM DATE
  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected formatDateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatDateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  @Watch('date')
  protected fromDateChanged() {
    this.formatDateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingFromChanged() {
    if (! this.date) {
      this.formatDateFromDatePicker();
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Productie Statistieken' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface PlanningProductionStatisticsMediatorsRpc {
  signature?: string,
  body?: {
    date?: string,
    appointment_types?: string[],
    type?: string,
    departments?: string[],
  }
}

interface PlanningProductionStatisticsExpertsRpc {
  signature?: string,
  body?: {
    date?: string,
    actor?: string,
    planning_type?: string,
    statusses?: string[],
    type?: string,
    departments?: string[],
  }
}
