var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v(
                " Ingepland per " +
                  _vm._s(
                    _vm.$store.state.isServiceOrganization
                      ? "Zaakbegeleider"
                      : "Deskundige"
                  ) +
                  " "
              )
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel", attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "align-baseline": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "dialog",
                            attrs: {
                              "close-on-content-click": false,
                              lazy: "",
                              "nudge-right": "130",
                              "full-width": "",
                              "max-width": "290px",
                              "min-width": "290px"
                            },
                            model: {
                              value: _vm.isEditingDate,
                              callback: function($$v) {
                                _vm.isEditingDate = $$v
                              },
                              expression: "isEditingDate"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-date",
                              attrs: {
                                slot: "activator",
                                "hide-details": "",
                                placeholder: "dd-mm-jjjj",
                                label: "Datum vanaf",
                                "error-messages": _vm.dateErrorMessage
                              },
                              on: {
                                change: function($event) {
                                  return _vm.formatDateFromTextField($event)
                                }
                              },
                              slot: "activator",
                              model: {
                                value: _vm.dateFormatted,
                                callback: function($$v) {
                                  _vm.dateFormatted = $$v
                                },
                                expression: "dateFormatted"
                              }
                            }),
                            _c("v-date-picker", {
                              ref: "picker",
                              attrs: {
                                locale: "nl-nl",
                                min: "1910-01-01",
                                color: "#837f16",
                                "first-day-of-week": "1"
                              },
                              on: {
                                change: function($event) {
                                  _vm.isEditingDate = false
                                  _vm.fetchUsers()
                                }
                              },
                              model: {
                                value: _vm.date,
                                callback: function($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "with-border",
                          attrs: {
                            items: _vm.departments,
                            "item-value": "id",
                            "item-text": "name",
                            "hide-details": "",
                            label: "Afdelingen",
                            clearable: "",
                            multiple: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.fetchUsers()
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var index = ref.index
                                return [
                                  index === 0
                                    ? _c("span", [
                                        _vm._v(
                                          "Afdeling (" +
                                            _vm._s(
                                              _vm.selectedDepartments.length
                                            ) +
                                            ")"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.selectedDepartments,
                            callback: function($$v) {
                              _vm.selectedDepartments = $$v
                            },
                            expression: "selectedDepartments"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.$store.state.isServiceOrganization
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm2: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.statusses,
                                "item-value": "key",
                                "item-text": "label",
                                "hide-details": "",
                                label: "Status",
                                clearable: "",
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var index = ref.index
                                      return [
                                        index === 0
                                          ? _c("span", [
                                              _vm._v(
                                                "Status (" +
                                                  _vm._s(
                                                    _vm.selectedStatusses.length
                                                  ) +
                                                  ")"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                475845484
                              ),
                              model: {
                                value: _vm.selectedStatusses,
                                callback: function($$v) {
                                  _vm.selectedStatusses = $$v
                                },
                                expression: "selectedStatusses"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "with-border",
                          attrs: {
                            items: _vm.planningTypes,
                            "item-value": "key",
                            "item-text": "name",
                            "hide-details": "",
                            label: "Planning Type"
                          },
                          on: {
                            change: function($event) {
                              return _vm.fetchUsers()
                            }
                          },
                          model: {
                            value: _vm.selectedPlanningType,
                            callback: function($$v) {
                              _vm.selectedPlanningType = $$v
                            },
                            expression: "selectedPlanningType"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.$store.state.isServiceOrganization
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm2: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.actors,
                                "item-value": "key",
                                "item-text": "name",
                                "hide-details": "",
                                label: "Rol"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              model: {
                                value: _vm.selectedActor,
                                callback: function($$v) {
                                  _vm.selectedActor = $$v
                                },
                                expression: "selectedActor"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.$store.state.isServiceOrganization &&
                    _vm.selectedPlanningType == "events"
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm2: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.expertAppointmentTypes,
                                "item-value": "key",
                                "item-text": "name",
                                "hide-details": "",
                                label: "Afspraak type",
                                clearable: "",
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var index = ref.index
                                      return [
                                        index === 0
                                          ? _c("span", [
                                              _vm._v(
                                                "Status (" +
                                                  _vm._s(
                                                    _vm
                                                      .selectedExpertAppointmentTypes
                                                      .length
                                                  ) +
                                                  ")"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2362515767
                              ),
                              model: {
                                value: _vm.selectedExpertAppointmentTypes,
                                callback: function($$v) {
                                  _vm.selectedExpertAppointmentTypes = $$v
                                },
                                expression: "selectedExpertAppointmentTypes"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.$store.state.isServiceOrganization &&
                    _vm.selectedPlanningType == "events"
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm2: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.workflows,
                                "item-value": "key",
                                "item-text": "name",
                                "hide-details": "",
                                label: "Workflow",
                                clearable: "",
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var index = ref.index
                                      return [
                                        index === 0
                                          ? _c("span", [
                                              _vm._v(
                                                "Status (" +
                                                  _vm._s(
                                                    _vm.selectedWorkflows.length
                                                  ) +
                                                  ")"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3731696093
                              ),
                              model: {
                                value: _vm.selectedWorkflows,
                                callback: function($$v) {
                                  _vm.selectedWorkflows = $$v
                                },
                                expression: "selectedWorkflows"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$store.state.isServiceOrganization &&
                    _vm.selectedPlanningType == "events"
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm2: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.appointmentTypes,
                                "item-value": "key",
                                "item-text": "name",
                                "hide-details": "",
                                clearable: "",
                                multiple: "",
                                label: "Soort Afspraak"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              model: {
                                value: _vm.selectedAppointmentTypes,
                                callback: function($$v) {
                                  _vm.selectedAppointmentTypes = $$v
                                },
                                expression: "selectedAppointmentTypes"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$store.state.isServiceOrganization &&
                    _vm.selectedPlanningType == "reports"
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm2: "" } },
                          [
                            _c("ReportTypeFilter", {
                              attrs: {
                                cssClass: "with-border",
                                canSelectAll: true,
                                selectables: _vm.selectableReportTypes
                              },
                              on: {
                                handleChange: function($event) {
                                  return _vm.fetchUsers()
                                }
                              },
                              model: {
                                value: _vm.selectedReportTypes,
                                callback: function($$v) {
                                  _vm.selectedReportTypes = $$v
                                },
                                expression: "selectedReportTypes"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: {
                        flat: "",
                        type: "spinner--center",
                        minHeight: "300px"
                      }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? _c("WeekStatisticsView", {
                      attrs: { items: _vm.users, columns: _vm.columns }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }